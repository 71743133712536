<template>
  <div>
    <!-- Filter -->
    <div class="row justify-content-end mt-n3">
      <div class="col" v-if="purpose == 'master'">
        <b-button
          variant="primary"
          @click="$bvModal.show('modal-hidden-doctor')"
          v-if="manipulateBtn == true"
          >Dokter yang disembunyikan</b-button
        >
      </div>
      <div class="col-md-3 col-12">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Nama Dokter"
            @keyup="filterByName"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>
    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      class="mt-3"
      :items="items"
      :fields="fields"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{
            width:
              field.key === 'actions' ? (purpose == 'master' ? '30%' : '') : '',
          }"
        />
      </template>
      <template #cell(name)="data">
        <span class="d-block"
          >{{ data.item.name }}
          <i
            v-if="data.item.is_satusehat_skip == 0"
            class="fas fa-stethoscope text-primary"
            v-b-tooltip.hover
            title="Dokter"
          ></i
        ></span>
      </template>
      <template #cell(email_mobile_phone)="data">
        <span class="d-block">{{ data.item.email }}</span>
        <span class="d-block">{{ data.item.mobile_phone }}</span>
      </template>
      <template #cell(photo)="data">
        <div
          class="d-flex justify-content-center align-items-center image-container"
        >
          <div class="" style="height: 50px; width: 50px; position: relative">
            <img class="image" :src="data.item.photo" />
          </div>
        </div>
      </template>
      <template #cell(actions)="data">
        <template v-if="purpose == 'master'">
          <div class="d-flex">
            <b-button
              size="sm"
              class="mr-1 btn-info"
              v-b-tooltip.hover
              title="Detail"
              @click="$router.push({ path: '/doctor/detail/' + data.item.id })"
              ><i class="fas fa-eye px-0"></i
            ></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-success"
              v-b-tooltip.hover
              title="Edit"
              v-if="manipulateBtn == true"
              @click="$router.push({ path: '/doctor/edit/' + data.item.id })"
              ><i class="fas fa-edit px-0"></i
            ></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-info"
              v-b-tooltip.hover
              title="Perjanjian"
              v-if="appointmentBtn == true"
              @click="
                $router.push({
                  path: '/doctor/doctor-appointment/' + data.item.id,
                })
              "
              ><i class="fas fa-tasks px-0"></i
            ></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-warning"
              v-b-tooltip.hover
              title="Libur"
              v-if="holidayBtn == true"
              @click="$router.push({ path: '/doctor/holiday/' + data.item.id })"
              ><i class="fas fa-calendar-times px-0"></i
            ></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-success"
              v-b-tooltip.hover
              title="Jadwal"
              v-if="scheduleBtn == true"
              @click="
                $router.push({ path: '/doctor/schedule/' + data.item.id })
              "
              ><i class="fas fa-calendar-check px-0"></i
            ></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-warning"
              v-b-tooltip.hover
              title="Sembunyikan"
              v-if="manipulateBtn == true"
              @click="btnHideOnClick(data.item.id, 1)"
              ><i class="fas fa-eye-slash px-0"></i
            ></b-button>
            <b-button
              size="sm"
              class="btn-danger"
              v-b-tooltip.hover
              title="Hapus"
              v-if="manipulateBtn == true"
              @click="btnDeleteOnClick(data.item.id)"
              ><i class="fas fa-trash px-0"></i
            ></b-button>
          </div>
        </template>
        <template v-if="purpose == 'modal'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="
              chooseDoctor({
                id: data.item.id,
                name: `${data.item.name} - ${data.item.polyclinic_name}`,
              })
            "
          >
            Pilih
          </b-button>
        </template>
        <template v-if="purpose == 'modal-doctor-multiple'">
          <b-form-checkbox
            :checked="data.item.checked"
            :id="`checkbox-pick-item-${data.item.id}`"
            :name="`checkbox-pick-item-${data.item.id}`"
            @change="chooseDoctorMultiple(data.item, $event)"
          >
          </b-form-checkbox>
        </template>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>

    <b-modal
      id="modal-hidden-doctor"
      size="xl"
      title="Daftar Dokter yang disembunyikan"
    >
      <b-table
        striped
        hover
        responsive
        class="mt-3"
        :items="hiddenItems"
        :fields="hiddenFields"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{
              width:
                field.key === 'actions'
                  ? purpose == 'master'
                    ? '5%'
                    : ''
                  : '',
            }"
          />
        </template>
        <template #cell(email_mobile_phone)="data">
          <span class="d-block">{{ data.item.email }}</span>
          <span class="d-block">{{ data.item.mobile_phone }}</span>
        </template>
        <template #cell(photo)="data">
          <div
            class="d-flex justify-content-center align-items-center image-container"
          >
            <div class="" style="height: 50px; width: 50px; position: relative">
              <img class="image" :src="data.item.photo" />
            </div>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button
              size="sm"
              class="mr-1 btn-warning"
              v-b-tooltip.hover
              title="Hapus dari daftar sembunyi"
              v-if="manipulateBtn == true"
              @click="btnHideOnClick(data.item.id, 0)"
              ><i class="fas fa-minus-circle px-0"></i
            ></b-button>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="hiddenItems.length != 0"
        v-model="hiddenCurrentPage"
        :total-rows="hiddenTotalRows"
        :per-page="hiddenPerPage"
        @page-click="hiddenPageOnClick"
        class="mt-4"
      ></b-pagination>
    </b-modal>
  </div>
</template>

<script>
import { debounce } from "@/core/modules/Helper.js";
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    purpose: String,
    polyclinicId: Number,
    doctorCollection: {
      type: Array,
      default: [],
    },
    filterModalDoctorMultiple: {
      type: Object,
      default: {
        ids: "",
      },
    },
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
        doctor_in_charge: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "email_mobile_phone",
          label: "Email & Nomor Handphone",
          sortable: true,
        },
        {
          key: "polyclinic_name",
          label: "Poliklinik",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // Hidden
      hiddenPerPage: 20,
      hiddenCurrentPage: 1,
      hiddenTotalRows: 1,
      hiddenFields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "email_mobile_phone",
          label: "Email & Nomor Handphone",
          sortable: true,
        },
        {
          key: "polyclinic_name",
          label: "Poliklinik",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      hiddenItems: [],
      // access management
      manipulateBtn: false,
      scheduleBtn: false,
      holidayBtn: false,
      appointmentBtn: false,
    };
  },

  methods: {
    async hiddenPageOnClick(evt, page) {
      evt.preventDefault();
      this.hiddenCurrentPage = page;
      await this.hiddenPagination();
    },

    async hiddenPagination() {
      // if (this.purpose == "polyclinic-modal") {
      //   filterParams += `&polyclinic_id=${this.polyclinicId}`;
      // }
      let filterParams = `&is_hide=${1}`;
      let response = await module.paginate(
        "doctors",
        `?page=${this.hiddenCurrentPage}&page_size=${this.hiddenPerPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.hiddenTotalRows = pagination.total;

      let a, b;
      b = this.hiddenPerPage * (this.hiddenCurrentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.hiddenItems = response.data;
    },

    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&ids=${this.filter.doctor_in_charge}`;
      if (this.purpose == "polyclinic-modal") {
        filterParams += `&polyclinic_id=${this.polyclinicId}`;
      }
      let response = await module.paginate(
        "doctors",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
      this.setCheckedItem();
    },

    filterByName() {
      debounce(() => {
        this.pagination();
      }, 500);
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("doctors/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    async btnHideOnClick(id, is_hide) {
      let formData = {
        id: id,
        is_hide: is_hide,
        _method: "put",
      };
      // Make Request
      let response = await module.submit(formData, `doctors/${id}/mini-update`);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        Swal.fire({
          title: "Gagal",
          text: "Gagal menyembunyikan dokter",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.pagination();
        this.hiddenPagination();
      }
    },

    chooseDoctor(value) {
      this.$emit("chooseDoctor", value);
    },

    chooseDoctorMultiple(data, evt) {
      if (evt) {
        this.$emit("checkDataMultiple", data);
      } else {
        this.$emit("unCheckDataMultiple", data);
      }
    },
    setCheckedItem() {
      console.log("di table ==============", this.doctorCollection);
      if (this.doctorCollection.length > 0) {
        console.log("doctorCollection length", this.doctorCollection.length);
        let found;
        this.items.forEach((value) => {
          found = this.doctorCollection.find((x) => x.id == value.id);

          if (typeof found === "undefined") {
            value.checked = false;
          } else {
            value.checked = true;
          }
        });
      }
      // localStorage.setItem("dataSelectedPackage", JSON.stringify(this.itemProps))
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "2002") {
          this.manipulateBtn = true;
        }
        if (access_right[a] == "4001") {
          this.scheduleBtn = true;
        }
        if (access_right[a] == "4003") {
          this.holidayBtn = true;
        }
        if (access_right[a] == "5001") {
          this.appointmentBtn = true;
        }
      }
    },
  },

  mounted() {
    // Get Data
    // access management
    this.setActiveMenu();

    this.$root.$on("cancelAddDoctorMultipleFromAddMedicalRecord", (data) => {
      for (let a = 0; a < this.items.length; a++) {
        if (this.items[a].id == data.id) {
          console.log("items", this.items[a].id);
          console.log("data", data.id);
          this.items[a].checked = false;
          console.log("items full", this.items[a]);
        }
      }
      this.items.splice(this.items.length);
    });

    if (this.filterModalDoctorMultiple.ids.length != 0) {
      this.filter.doctor_in_charge = this.filterModalDoctorMultiple.ids;
    }

    this.pagination();
    this.hiddenPagination();
  },
  watch: {
    filterModalDoctorMultiple: function (newVal, oldVal) {
      this.filter.doctor_in_charge = newVal;
      this.pagination();
    },
  },
};
</script>